
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";

export default defineComponent({
  name: "flex-layout",
  components: {
    CodeHighlighter
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Flex Layout");
    });
  }
});
